.root {
  height: 30px;
  padding: 0 var(--inner-spacing-4);
  background-color: var(--bg-neutral-subtle-alt, #e7e8e8);
  border-radius: var(--radius-inner-button, 1.8px);

  &:hover {
    background-color: var(--bg-neutral-subtle-alt-hover, #f0f1f1);
  }

  &:focus-visible {
    box-shadow:
      0 0 0 2px var(--color-bg),
      0 0 0 4px var(--color-bd-focus);
  }

  &:active {
    background-color: var(--bg-neutral-subtle-alt-active, #e1e2e2);
  }
}
