.root {
  display: flex;
  gap: var(--inner-spacing-4);
  padding: var(--inner-spacing-3) 0;
}

@container (min-width: 700px) {
  .root {
    padding: var(--inner-spacing-5) 0;
  }
}

.root[data-assistant="false"] {
  flex-direction: row-reverse;
}

.root[data-assistant="false"] .sentTime {
  text-align: right;
}

.sentTime {
  margin: 0 0 var(--outer-spacing-3);
  /* TODO: --type-caption doesn't exists. Define it */
  font-size: var(--type-caption, 12.247px);
  /* TODO: --type-caption-lineheight doesn't exists. Define it */
  line-height: var(--type-caption-lineheight, 17.25px);
}
